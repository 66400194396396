export const getTaskType = (taskAggregation) => {
  const { domain } = taskAggregation;

  switch (domain) {
    case 'document':
      return getDocumentDomainTaskType(taskAggregation);
    case 'product':
      return getProductDomainTaskType(taskAggregation);
    case 'payment':
      return getPaymentDomainTaskType(taskAggregation);
    case 'delivery':
      return getDeliveryDomainTaskType(taskAggregation);
    default:
      return null;
  }
};

const getDocumentDomainTaskType = (taskAggregation) => {
  const { type } = taskAggregation;

  switch (type) {
    case 'record':
      return 'documentRecord';
    case 'upload':
      return 'documentUpload';
    case null:
    case 'missingDocuments':
      return 'documentRescan';
    default:
      return null;
  }
};

const getProductDomainTaskType = (taskAggregation) => {
  const { type } = taskAggregation;

  switch (type) {
    case null:
    case 'mergeProducts':
      return 'productMerge';
    default:
      return null;
  }
};

const getDeliveryDomainTaskType = (taskAggregation) => {
  const { type } = taskAggregation;

  switch (type) {
    case 'declare':
      return 'declaration';
    default:
      return null;
  }
};

const getPaymentDomainTaskType = (taskAggregation) => {
  const { type } = taskAggregation;

  switch (type) {
    case null:
      return 'paymentRecord';
    default:
      return null;
  }
};
