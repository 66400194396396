<template>
  <div>
    <div class="d-flex align-items-center mb-4">
      <div :class="$direction === 'ltr' ? 'me-2' : 'ms-2'">
        <slot />
      </div>
      <h2>{{ $t(`operations.overview.categories.${category}.header`) }}</h2>
    </div>
    <div class="w-50">
      <table class="w-100 table-vertical-spacing">
        <thead>
          <tr>
            <th class="pb-2" style="width: 44%">
              <p>{{ $t('operations.overview.headers.taskType') }}</p>
            </th>
            <th class="px-0 pb-2" style="width: 21%">
              <p>{{ $t('operations.overview.headers.taskCount') }}</p>
            </th>
            <th class="px-0 pb-2" style="width: 35%">
              <p>{{ $t('operations.overview.headers.idleTime') }}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <TasksStatusRow
            v-for="data in taskData"
            :key="data.taskType"
            :data="data"
            :rows-clickable="true"
            @rowClick="navigateToOverviewByBusiness"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import TasksStatusRow from '@/modules/operations/components/TasksStatusRow';

export default {
  components: { TasksStatusRow },
  props: {
    category: { type: String, required: true },
    taskData: { type: Array, required: true },
  },
  methods: {
    navigateToOverviewByBusiness(data) {
      this.$router.push({
        name: 'operationsByBusiness',
        query: { taskDomain: data.domain, taskType: data.taskType },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table-vertical-spacing {
  border-collapse: separate;
  border-spacing: 0 8px;
}
</style>
