<template>
  <page-layout :ready="!loading" class="bg-white">
    <template slot="breadcrumb">
      <el-breadcrumb-item :to="{ name: 'operations' }">
        {{ $t('operations.overview.header') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{
        $t(`operations.overview.categories.${taskDomain}.types.${taskType}`)
      }}</el-breadcrumb-item>
    </template>
    <BusinessTaskStatus class="mb-7" :task-data="taskData" />
  </page-layout>
</template>

<script>
import { ref } from 'vue';

import { useTasksAggregation } from '@/modules/tasks';

import BusinessTaskStatus from './components/BusinessTaskStatus';
import { getTaskType } from './compositions/tasks';

const taskDataByBusinessIdResolver = (acc, currAggregation) => {
  if (!acc[currAggregation.businessId]) {
    acc[currAggregation.businessId] = {
      standardTimeTasksCount: 0,
      inadequateTimeTasksCount: 0,
      lateTimeTasksCount: 0,
      title: currAggregation.business.name,
    };
  }

  if (!currAggregation.daysOpen) acc[currAggregation.businessId].standardTimeTasksCount += currAggregation.count;
  else if (currAggregation.daysOpen === 1)
    acc[currAggregation.businessId].inadequateTimeTasksCount += currAggregation.count;
  else acc[currAggregation.businessId].lateTimeTasksCount += currAggregation.count;
  return acc;
};

export default {
  components: { BusinessTaskStatus },
  setup() {
    const { aggregations, loading, refetch } = useTasksAggregation(
      ref({ filters: { completed: false }, options: { groupBy: ['businessId', 'type', 'daysOpen', 'domain'] } })
    );

    const refetchInterval = setInterval(refetch, 30000);

    return {
      aggregations,
      loading,
      refetchInterval,
    };
  },
  computed: {
    taskData() {
      const taskDataByBusinessId = this.aggregations
        .filter((aggregation) => getTaskType(aggregation) === this.taskType)
        .reduce(taskDataByBusinessIdResolver, {});
      return Object.entries(taskDataByBusinessId).map(([businessId, data]) => ({ businessId, ...data }));
    },
    taskType() {
      return this.$route.query.taskType;
    },
    taskDomain() {
      return this.$route.query.taskDomain;
    },
  },
  destroyed() {
    if (this.refetchInterval) {
      clearInterval(this.refetchInterval);
      this.refetchInterval = null;
    }
  },
};
</script>
