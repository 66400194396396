<template>
  <div class="w-50">
    <table class="w-100 table-vertical-spacing">
      <thead>
        <tr>
          <th class="pb-2" style="width: 44%">
            <p>{{ $t('operations.overview.headers.restaurant') }}</p>
          </th>
          <th class="px-0 pb-2" style="width: 21%">
            <p>{{ $t('operations.overview.headers.taskCount') }}</p>
          </th>
          <th class="px-0 pb-2" style="width: 35%">
            <p>{{ $t('operations.overview.headers.idleTime') }}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <TasksStatusRow v-for="data in taskData" :key="data.title" :data="data" />
      </tbody>
    </table>
  </div>
</template>

<script>
import TasksStatusRow from './TasksStatusRow';

export default {
  components: { TasksStatusRow },
  props: {
    taskData: { type: Array, required: true },
  },
};
</script>

<style scoped lang="scss">
.table-vertical-spacing {
  border-collapse: separate;
  border-spacing: 0 8px;
}
</style>
