<template>
  <tr class="mb-2 task-status-row">
    <td class="row-title">
      <div class="d-flex align-items-center bg-light" :class="$direction === 'ltr' ? 'me-2' : 'ms-2'">
        <p class="py-2 fw-bold" :class="$direction === 'ltr' ? 'ps-2' : 'pe-2'">
          {{ data.title }}
        </p>
      </div>
    </td>
    <td class="px-0 row-count">
      <div
        class="d-flex align-items-center bg-light row-count-container"
        :class="rowsClickable ? 'clickable' : ''"
        @click="rowsClickable && handleCellClick($event)"
      >
        <p class="py-2 fw-bold text-typography-primary" :class="$direction === 'ltr' ? 'ps-2' : 'pe-2'">
          {{ taskCount }}
        </p>
      </div>
    </td>
    <td class="px-0 row-times">
      <div
        v-if="taskCount"
        class="row g-0 row-times-container"
        :class="rowsClickable ? 'clickable' : ''"
        @click="rowsClickable && handleCellClick($event)"
      >
        <template v-if="data.standardTimeTasksCount">
          <el-tooltip placement="top" :content="$t('operations.overview.tooltips.below24Hours')">
            <div
              v-if="data.standardTimeTasksCount"
              :class="`col-${taskTimeColumnSize.standardTimeColumnSize}`"
              class="d-flex align-items-center justify-content-center bg-primary"
            >
              <p class="p-2 text-white">{{ data.standardTimeTasksCount }}</p>
            </div>
          </el-tooltip>
        </template>

        <template v-if="data.inadequateTimeTasksCount">
          <el-tooltip placement="top" :content="$t('operations.overview.tooltips.above24Hours')">
            <div
              :class="`col-${taskTimeColumnSize.inadequateTimeColumnSize}`"
              class="d-flex align-items-center justify-content-center bg-warning"
            >
              <p class="p-2 text-white">{{ data.inadequateTimeTasksCount }}</p>
            </div>
          </el-tooltip>
        </template>

        <template v-if="data.lateTimeTasksCount">
          <el-tooltip class="item" placement="top" :content="$t('operations.overview.tooltips.above48Hours')">
            <div
              :class="`col-${taskTimeColumnSize.lateTimeColumnSize}`"
              class="d-flex align-items-center justify-content-center bg-danger"
            >
              <p class="p-2 text-white">{{ data.lateTimeTasksCount }}</p>
            </div>
          </el-tooltip>
        </template>
      </div>
    </td>
  </tr>
</template>

<script>
const calculateColumnSize = (taskCount, totalTaskCount) => {
  const percent = taskCount / totalTaskCount;
  if (percent && percent < 0.1) return 1;
  if (percent > 0.9) return 10;
  return Math.floor(percent * 12);
};

export default {
  props: {
    data: { type: Object, required: true },
    rowsClickable: { type: Boolean, default: false },
  },
  computed: {
    taskCount() {
      return this.data.standardTimeTasksCount + this.data.inadequateTimeTasksCount + this.data.lateTimeTasksCount;
    },
    taskTimeColumnSize() {
      let standardTimeColumnSize = calculateColumnSize(this.data.standardTimeTasksCount, this.taskCount);
      let inadequateTimeColumnSize = calculateColumnSize(this.data.inadequateTimeTasksCount, this.taskCount);
      let lateTimeColumnSize = calculateColumnSize(this.data.lateTimeTasksCount, this.taskCount);

      const totalColumnSizes = standardTimeColumnSize + inadequateTimeColumnSize + lateTimeColumnSize;

      if (totalColumnSizes < 12) {
        const biggestColumnSize = Math.max(standardTimeColumnSize, inadequateTimeColumnSize, lateTimeColumnSize);
        if (standardTimeColumnSize === biggestColumnSize) standardTimeColumnSize += 12 - totalColumnSizes;
        else if (inadequateTimeColumnSize === biggestColumnSize) inadequateTimeColumnSize += 12 - totalColumnSizes;
        else lateTimeColumnSize += 12 - totalColumnSizes;
      }

      return {
        standardTimeColumnSize,
        inadequateTimeColumnSize,
        lateTimeColumnSize,
      };
    },
  },
  methods: {
    handleCellClick() {
      this.$emit('rowClick', this.data);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.clickable {
  cursor: pointer;
}

.task-status-row:hover {
  & .row-count {
    & div {
      background: darken($light-gray, 5%) !important;
    }
  }

  $darken-percentage-first-level: 15%;
  $darken-percentage-second-level: 25%;

  & .row-times {
    & div.bg-primary {
      background: darken($primary, $darken-percentage-first-level) !important;

      &:hover {
        background: darken($primary, $darken-percentage-second-level) !important;
      }
    }
    & div.bg-warning {
      background: darken($warning, $darken-percentage-first-level) !important;

      &:hover {
        background: darken($warning, $darken-percentage-second-level) !important;
      }
    }
    & div.bg-danger {
      background: darken($error, $darken-percentage-first-level) !important;

      &:hover {
        background: darken($error, $darken-percentage-second-level) !important;
      }
    }
  }
}
</style>
